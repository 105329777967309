/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BillingPeriod } from '../models/billing-period';
import { ChangePlanDto } from '../models/change-plan-dto';
import { Page } from '../models/page';
import { SortOrder } from '../models/sort-order';
import { Usage } from '../models/usage';

@Injectable({ providedIn: 'root' })
export class BillingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `usageHistory()` */
  static readonly UsageHistoryPath = '/billing/usage-history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usageHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  usageHistory$Response(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<Usage>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, BillingService.UsageHistoryPath, 'get');
    if (params) {
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<Usage>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usageHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usageHistory(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<Usage>;
}> {
    return this.usageHistory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<Usage>;
}>): Page & {
'result': Array<Usage>;
} => r.body)
    );
  }

  /** Path part for operation `changePlan()` */
  static readonly ChangePlanPath = '/billing/change';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePlan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePlan$Response(
    params: {
      body: ChangePlanDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BillingPeriod>> {
    const rb = new RequestBuilder(this.rootUrl, BillingService.ChangePlanPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BillingPeriod>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changePlan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePlan(
    params: {
      body: ChangePlanDto
    },
    context?: HttpContext
  ): Observable<BillingPeriod> {
    return this.changePlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<BillingPeriod>): BillingPeriod => r.body)
    );
  }

}
