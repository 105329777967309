/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AddChecklistCriteriaV1Dto } from '../models/add-checklist-criteria-v-1-dto';
import { AddChecklistLevelV1Dto } from '../models/add-checklist-level-v-1-dto';
import { AdminChecklistTemplate } from '../models/admin-checklist-template';
import { AdminChecklistTemplateDetailsV1 } from '../models/admin-checklist-template-details-v-1';
import { Attachment } from '../models/attachment';
import { CreateAdminChecklist } from '../models/create-admin-checklist';
import { Page } from '../models/page';
import { Pictogram } from '../models/pictogram';
import { SortOrder } from '../models/sort-order';
import { UpdateAdminChecklist } from '../models/update-admin-checklist';
import { UpdateAdminPropertyAttachmentDto } from '../models/update-admin-property-attachment-dto';
import { UpdateChecklistCriteriaV1Dto } from '../models/update-checklist-criteria-v-1-dto';
import { UpdatePictogramDto } from '../models/update-pictogram-dto';
import { UploadAdminPropertyAttachmentDto } from '../models/upload-admin-property-attachment-dto';

@Injectable({ providedIn: 'root' })
export class AdminService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminChecklists()` */
  static readonly AdminChecklistsPath = '/admin/checklists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminChecklists()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminChecklists$Response(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<AdminChecklistTemplate>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.AdminChecklistsPath, 'get');
    if (params) {
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<AdminChecklistTemplate>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminChecklists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminChecklists(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<AdminChecklistTemplate>;
}> {
    return this.adminChecklists$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<AdminChecklistTemplate>;
}>): Page & {
'result': Array<AdminChecklistTemplate>;
} => r.body)
    );
  }

  /** Path part for operation `createAdminChecklist()` */
  static readonly CreateAdminChecklistPath = '/admin/checklists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAdminChecklist()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAdminChecklist$Response(
    params: {
      body: CreateAdminChecklist
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdminChecklistTemplate>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.CreateAdminChecklistPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdminChecklistTemplate>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAdminChecklist$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAdminChecklist(
    params: {
      body: CreateAdminChecklist
    },
    context?: HttpContext
  ): Observable<AdminChecklistTemplate> {
    return this.createAdminChecklist$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminChecklistTemplate>): AdminChecklistTemplate => r.body)
    );
  }

  /** Path part for operation `deleteAdminChecklist()` */
  static readonly DeleteAdminChecklistPath = '/admin/checklists/{checklistId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAdminChecklist()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAdminChecklist$Response(
    params: {
      checklistId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteAdminChecklistPath, 'delete');
    if (params) {
      rb.path('checklistId', params.checklistId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAdminChecklist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAdminChecklist(
    params: {
      checklistId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteAdminChecklist$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateAdminChecklist()` */
  static readonly UpdateAdminChecklistPath = '/admin/checklists/{checklistId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAdminChecklist()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAdminChecklist$Response(
    params: {
      checklistId: number;
      body: UpdateAdminChecklist
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdminChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.UpdateAdminChecklistPath, 'patch');
    if (params) {
      rb.path('checklistId', params.checklistId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdminChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAdminChecklist$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAdminChecklist(
    params: {
      checklistId: number;
      body: UpdateAdminChecklist
    },
    context?: HttpContext
  ): Observable<AdminChecklistTemplateDetailsV1> {
    return this.updateAdminChecklist$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminChecklistTemplateDetailsV1>): AdminChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `publishAdminChecklist()` */
  static readonly PublishAdminChecklistPath = '/admin/checklists/{checklistId}/publish';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publishAdminChecklist()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishAdminChecklist$Response(
    params: {
      checklistId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.PublishAdminChecklistPath, 'patch');
    if (params) {
      rb.path('checklistId', params.checklistId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publishAdminChecklist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishAdminChecklist(
    params: {
      checklistId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.publishAdminChecklist$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `unpublishAdminChecklist()` */
  static readonly UnpublishAdminChecklistPath = '/admin/checklists/{checklistId}/unpublish';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unpublishAdminChecklist()` instead.
   *
   * This method doesn't expect any request body.
   */
  unpublishAdminChecklist$Response(
    params: {
      checklistId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.UnpublishAdminChecklistPath, 'patch');
    if (params) {
      rb.path('checklistId', params.checklistId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unpublishAdminChecklist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unpublishAdminChecklist(
    params: {
      checklistId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.unpublishAdminChecklist$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminChecklistV1()` */
  static readonly AdminChecklistV1Path = '/admin/checklist-v1/{checklistId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminChecklistV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminChecklistV1$Response(
    params: {
      checklistId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdminChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.AdminChecklistV1Path, 'get');
    if (params) {
      rb.path('checklistId', params.checklistId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdminChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminChecklistV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminChecklistV1(
    params: {
      checklistId: number;
    },
    context?: HttpContext
  ): Observable<AdminChecklistTemplateDetailsV1> {
    return this.adminChecklistV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminChecklistTemplateDetailsV1>): AdminChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `addAdminChecklistLevelV1()` */
  static readonly AddAdminChecklistLevelV1Path = '/admin/checklist-v1/{checklistId}/levels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addAdminChecklistLevelV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addAdminChecklistLevelV1$Response(
    params: {
      checklistId: number;
      body: AddChecklistLevelV1Dto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdminChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.AddAdminChecklistLevelV1Path, 'post');
    if (params) {
      rb.path('checklistId', params.checklistId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdminChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addAdminChecklistLevelV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addAdminChecklistLevelV1(
    params: {
      checklistId: number;
      body: AddChecklistLevelV1Dto
    },
    context?: HttpContext
  ): Observable<AdminChecklistTemplateDetailsV1> {
    return this.addAdminChecklistLevelV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminChecklistTemplateDetailsV1>): AdminChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `deleteAdminChecklistLevelV1()` */
  static readonly DeleteAdminChecklistLevelV1Path = '/admin/checklist-v1/{checklistId}/levels/{levelId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAdminChecklistLevelV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAdminChecklistLevelV1$Response(
    params: {
      checklistId: number;
      levelId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdminChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteAdminChecklistLevelV1Path, 'delete');
    if (params) {
      rb.path('checklistId', params.checklistId, {});
      rb.path('levelId', params.levelId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdminChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAdminChecklistLevelV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAdminChecklistLevelV1(
    params: {
      checklistId: number;
      levelId: number;
    },
    context?: HttpContext
  ): Observable<AdminChecklistTemplateDetailsV1> {
    return this.deleteAdminChecklistLevelV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminChecklistTemplateDetailsV1>): AdminChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `updateAdminChecklistLevelV1()` */
  static readonly UpdateAdminChecklistLevelV1Path = '/admin/checklist-v1/{checklistId}/levels/{levelId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAdminChecklistLevelV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAdminChecklistLevelV1$Response(
    params: {
      checklistId: number;
      levelId: number;
      body: AddChecklistLevelV1Dto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdminChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.UpdateAdminChecklistLevelV1Path, 'patch');
    if (params) {
      rb.path('checklistId', params.checklistId, {});
      rb.path('levelId', params.levelId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdminChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAdminChecklistLevelV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAdminChecklistLevelV1(
    params: {
      checklistId: number;
      levelId: number;
      body: AddChecklistLevelV1Dto
    },
    context?: HttpContext
  ): Observable<AdminChecklistTemplateDetailsV1> {
    return this.updateAdminChecklistLevelV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminChecklistTemplateDetailsV1>): AdminChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `addAdminChecklistCriteriaV1()` */
  static readonly AddAdminChecklistCriteriaV1Path = '/admin/checklist-v1/{checklistId}/levels/{levelId}/criteria';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addAdminChecklistCriteriaV1()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  addAdminChecklistCriteriaV1$Response(
    params: {
      checklistId: number;
      levelId: number;
      body: AddChecklistCriteriaV1Dto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdminChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.AddAdminChecklistCriteriaV1Path, 'post');
    if (params) {
      rb.path('checklistId', params.checklistId, {});
      rb.path('levelId', params.levelId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdminChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addAdminChecklistCriteriaV1$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  addAdminChecklistCriteriaV1(
    params: {
      checklistId: number;
      levelId: number;
      body: AddChecklistCriteriaV1Dto
    },
    context?: HttpContext
  ): Observable<AdminChecklistTemplateDetailsV1> {
    return this.addAdminChecklistCriteriaV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminChecklistTemplateDetailsV1>): AdminChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `deleteAdminChecklistCriteriaV1()` */
  static readonly DeleteAdminChecklistCriteriaV1Path = '/admin/checklist-v1/{checklistId}/levels/{levelId}/criteria/{criteriaId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAdminChecklistCriteriaV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAdminChecklistCriteriaV1$Response(
    params: {
      checklistId: number;
      levelId: number;
      criteriaId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdminChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteAdminChecklistCriteriaV1Path, 'delete');
    if (params) {
      rb.path('checklistId', params.checklistId, {});
      rb.path('levelId', params.levelId, {});
      rb.path('criteriaId', params.criteriaId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdminChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAdminChecklistCriteriaV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAdminChecklistCriteriaV1(
    params: {
      checklistId: number;
      levelId: number;
      criteriaId: number;
    },
    context?: HttpContext
  ): Observable<AdminChecklistTemplateDetailsV1> {
    return this.deleteAdminChecklistCriteriaV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminChecklistTemplateDetailsV1>): AdminChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `updateAdminChecklistCriteriaV1()` */
  static readonly UpdateAdminChecklistCriteriaV1Path = '/admin/checklist-v1/{checklistId}/levels/{levelId}/criteria/{criteriaId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAdminChecklistCriteriaV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAdminChecklistCriteriaV1$Response(
    params: {
      checklistId: number;
      levelId: number;
      criteriaId: number;
      body: UpdateChecklistCriteriaV1Dto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdminChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.UpdateAdminChecklistCriteriaV1Path, 'patch');
    if (params) {
      rb.path('checklistId', params.checklistId, {});
      rb.path('levelId', params.levelId, {});
      rb.path('criteriaId', params.criteriaId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdminChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAdminChecklistCriteriaV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAdminChecklistCriteriaV1(
    params: {
      checklistId: number;
      levelId: number;
      criteriaId: number;
      body: UpdateChecklistCriteriaV1Dto
    },
    context?: HttpContext
  ): Observable<AdminChecklistTemplateDetailsV1> {
    return this.updateAdminChecklistCriteriaV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminChecklistTemplateDetailsV1>): AdminChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `uploadAdminChecklistCriteriaAttachments()` */
  static readonly UploadAdminChecklistCriteriaAttachmentsPath = '/admin/checklists/{checklistId}/levels/{levelId}/criteria/{criteriaId}/attachments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadAdminChecklistCriteriaAttachments()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAdminChecklistCriteriaAttachments$Response(
    params: {
      checklistId: number;
      levelId: number;
      criteriaId: number;
      body: {
'attachments': Array<Blob>;
}
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdminChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.UploadAdminChecklistCriteriaAttachmentsPath, 'post');
    if (params) {
      rb.path('checklistId', params.checklistId, {});
      rb.path('levelId', params.levelId, {});
      rb.path('criteriaId', params.criteriaId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdminChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadAdminChecklistCriteriaAttachments$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAdminChecklistCriteriaAttachments(
    params: {
      checklistId: number;
      levelId: number;
      criteriaId: number;
      body: {
'attachments': Array<Blob>;
}
    },
    context?: HttpContext
  ): Observable<AdminChecklistTemplateDetailsV1> {
    return this.uploadAdminChecklistCriteriaAttachments$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminChecklistTemplateDetailsV1>): AdminChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `deleteAdminChecklistCriteriaAttachment()` */
  static readonly DeleteAdminChecklistCriteriaAttachmentPath = '/admin/checklists/{checklistId}/levels/{levelId}/criteria/{criteriaId}/attachments/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAdminChecklistCriteriaAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAdminChecklistCriteriaAttachment$Response(
    params: {
      checklistId: number;
      levelId: number;
      criteriaId: number;
      attachmentId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdminChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteAdminChecklistCriteriaAttachmentPath, 'delete');
    if (params) {
      rb.path('checklistId', params.checklistId, {});
      rb.path('levelId', params.levelId, {});
      rb.path('criteriaId', params.criteriaId, {});
      rb.path('attachmentId', params.attachmentId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdminChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAdminChecklistCriteriaAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAdminChecklistCriteriaAttachment(
    params: {
      checklistId: number;
      levelId: number;
      criteriaId: number;
      attachmentId: string;
    },
    context?: HttpContext
  ): Observable<AdminChecklistTemplateDetailsV1> {
    return this.deleteAdminChecklistCriteriaAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminChecklistTemplateDetailsV1>): AdminChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `uploadAdminPicto()` */
  static readonly UploadAdminPictoPath = '/admin/picto';

  /**
   * Only supports files with mimetype `image/*`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadAdminPicto()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAdminPicto$Response(
    params: {
      body: {
'pictogram': Blob;
}
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.UploadAdminPictoPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Only supports files with mimetype `image/*`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadAdminPicto$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAdminPicto(
    params: {
      body: {
'pictogram': Blob;
}
    },
    context?: HttpContext
  ): Observable<void> {
    return this.uploadAdminPicto$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `pageAdminPictos()` */
  static readonly PageAdminPictosPath = '/admin/pictos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pageAdminPictos()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageAdminPictos$Response(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<Pictogram>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.PageAdminPictosPath, 'get');
    if (params) {
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<Pictogram>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pageAdminPictos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pageAdminPictos(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<Pictogram>;
}> {
    return this.pageAdminPictos$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<Pictogram>;
}>): Page & {
'result': Array<Pictogram>;
} => r.body)
    );
  }

  /** Path part for operation `uploadAdminPictos()` */
  static readonly UploadAdminPictosPath = '/admin/pictos';

  /**
   * Only supports files with mimetype `image/*` and allows 20 images at a time
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadAdminPictos()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAdminPictos$Response(
    params: {
      body: {
'pictograms': Array<Blob>;
}
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.UploadAdminPictosPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Only supports files with mimetype `image/*` and allows 20 images at a time
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadAdminPictos$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAdminPictos(
    params: {
      body: {
'pictograms': Array<Blob>;
}
    },
    context?: HttpContext
  ): Observable<void> {
    return this.uploadAdminPictos$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteAdminPictos()` */
  static readonly DeleteAdminPictosPath = '/admin/pictos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAdminPictos()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAdminPictos$Response(
    params: {
      pictoIds: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteAdminPictosPath, 'delete');
    if (params) {
      rb.query('pictoIds', params.pictoIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAdminPictos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAdminPictos(
    params: {
      pictoIds: Array<number>;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteAdminPictos$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateAdminPicto()` */
  static readonly UpdateAdminPictoPath = '/admin/pictos/{pictoId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAdminPicto()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAdminPicto$Response(
    params: {
      pictoId: number;
      body: UpdatePictogramDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Pictogram>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.UpdateAdminPictoPath, 'patch');
    if (params) {
      rb.path('pictoId', params.pictoId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Pictogram>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAdminPicto$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAdminPicto(
    params: {
      pictoId: number;
      body: UpdatePictogramDto
    },
    context?: HttpContext
  ): Observable<Pictogram> {
    return this.updateAdminPicto$Response(params, context).pipe(
      map((r: StrictHttpResponse<Pictogram>): Pictogram => r.body)
    );
  }

  /** Path part for operation `sendAdminPicto()` */
  static readonly SendAdminPictoPath = '/admin/pictos/{pictoId}/content';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendAdminPicto()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendAdminPicto$Response(
    params: {
      pictoId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Blob>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.SendAdminPictoPath, 'get');
    if (params) {
      rb.path('pictoId', params.pictoId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: 'image/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendAdminPicto$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendAdminPicto(
    params: {
      pictoId: number;
    },
    context?: HttpContext
  ): Observable<Blob> {
    return this.sendAdminPicto$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `replaceAdminPicto()` */
  static readonly ReplaceAdminPictoPath = '/admin/pictos/{pictoId}/replace';

  /**
   * Only supports files with mimetype `image/*`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replaceAdminPicto()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  replaceAdminPicto$Response(
    params: {
      pictoId: number;
      body: {
'pictogram': Blob;
}
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.ReplaceAdminPictoPath, 'put');
    if (params) {
      rb.path('pictoId', params.pictoId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Only supports files with mimetype `image/*`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `replaceAdminPicto$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  replaceAdminPicto(
    params: {
      pictoId: number;
      body: {
'pictogram': Blob;
}
    },
    context?: HttpContext
  ): Observable<void> {
    return this.replaceAdminPicto$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `listAdminPropertyAttachments()` */
  static readonly ListAdminPropertyAttachmentsPath = '/admin/property-attachments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAdminPropertyAttachments()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAdminPropertyAttachments$Response(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<Attachment>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.ListAdminPropertyAttachmentsPath, 'get');
    if (params) {
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<Attachment>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAdminPropertyAttachments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAdminPropertyAttachments(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<Attachment>;
}> {
    return this.listAdminPropertyAttachments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<Attachment>;
}>): Page & {
'result': Array<Attachment>;
} => r.body)
    );
  }

  /** Path part for operation `uploadAdminPropertyAttachment()` */
  static readonly UploadAdminPropertyAttachmentPath = '/admin/property-attachments';

  /**
   * Only supports files with mimetype `application/pdf` and `image/*`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadAdminPropertyAttachment()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAdminPropertyAttachment$Response(
    params: {
      body: UploadAdminPropertyAttachmentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.UploadAdminPropertyAttachmentPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Only supports files with mimetype `application/pdf` and `image/*`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadAdminPropertyAttachment$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadAdminPropertyAttachment(
    params: {
      body: UploadAdminPropertyAttachmentDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.uploadAdminPropertyAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteAdminPropertyAttachments()` */
  static readonly DeleteAdminPropertyAttachmentsPath = '/admin/property-attachments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAdminPropertyAttachments()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAdminPropertyAttachments$Response(
    params: {
      attachmentIds: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteAdminPropertyAttachmentsPath, 'delete');
    if (params) {
      rb.query('attachmentIds', params.attachmentIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAdminPropertyAttachments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAdminPropertyAttachments(
    params: {
      attachmentIds: Array<string>;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteAdminPropertyAttachments$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateAdminPropertyAttachment()` */
  static readonly UpdateAdminPropertyAttachmentPath = '/admin/property-attachments/{attachmentId}';

  /**
   * Only supports files with mimetype `application/pdf` and `image/*`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAdminPropertyAttachment()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateAdminPropertyAttachment$Response(
    params: {
      attachmentId: string;
      body: UpdateAdminPropertyAttachmentDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.UpdateAdminPropertyAttachmentPath, 'put');
    if (params) {
      rb.path('attachmentId', params.attachmentId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Only supports files with mimetype `application/pdf` and `image/*`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAdminPropertyAttachment$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateAdminPropertyAttachment(
    params: {
      attachmentId: string;
      body: UpdateAdminPropertyAttachmentDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateAdminPropertyAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
