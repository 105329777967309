import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { PlanType } from '@api/models';
import { FormlyFieldConfig } from '@ngx-formly/core';
import * as iban from 'iban';

export function RequiredValidationMessage(err, field: FormlyFieldConfig) {
  return 'Dieses Feld wird benötigt.';
}
export function MinlengthValidationMessage(err, field: FormlyFieldConfig) {
  return `Bitte geben Sie für ${field.props.label} mindestens ${field.props.minLength} Zeichen ein.`;
}

export function MaxlengthValidationMessage(err, field: FormlyFieldConfig) {
  return `Bitte geben Sie für ${field.props.label} höchstens ${field.props.minLength} Zeichen ein.`;
}

export const PhoneValidator = (control: UntypedFormControl) => {
  return !control.value || /^([0(+][0-9\.-\/ (-|\/)]{7,})$/.test(control.value)
    ? null
    : {
        phone: true,
      };
};

export function PhoneValidationMessage(err, field: FormlyFieldConfig) {
  return `"${field.formControl.value}"" ist keine gültige Telefonnummer.`;
}

export function EmailValidator(control: UntypedFormControl): ValidationErrors {
  return !control.value ||
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(control.value)
    ? null
    : { email: true };
}

export function EmailValidatorMessage(err, field: FormlyFieldConfig) {
  return `"${field.formControl.value}" ist keine gültige E-Mail Adresse.`;
}

export function BicValidator(control: UntypedFormControl): ValidationErrors {
  return !control.value ||
    /^([A-Z]{6}[A-Z2-9][A-NP-Z1-2])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(
      control.value,
    )
    ? null
    : { bic: true };
}

export function BicValidatorMessage(err, field: FormlyFieldConfig) {
  return `"${field.formControl.value}" ist keine gültige BIC.`;
}

export function IbanValidator(control: UntypedFormControl): ValidationErrors {
  return !control.value || iban.isValid(control.value) ? null : { iban: true };
}

export function IbanValidatorMessage(err, field: FormlyFieldConfig) {
  return `"${field.formControl.value}" ist keine gültige IBAN.`;
}

export function IsPlanTypeValidator(
  control: UntypedFormControl,
): ValidationErrors {
  const allowedPlans = Object.values(PlanType);
  return !control.value || allowedPlans.includes(control.value)
    ? null
    : { isPlanType: true };
}

export function IsPlanTypeValidatorMessage(err, field: FormlyFieldConfig) {
  return `"${field.formControl.value}" ist kein gültiger Plan.`;
}
