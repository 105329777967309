/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AddChecklistCriteriaV1Dto } from '../models/add-checklist-criteria-v-1-dto';
import { AddChecklistLevelV1Dto } from '../models/add-checklist-level-v-1-dto';
import { ChecklistTemplate } from '../models/checklist-template';
import { ChecklistTemplateDetailsV1 } from '../models/checklist-template-details-v-1';
import { ChecklistTemplateMode } from '../models/checklist-template-mode';
import { CreateChecklistTemplateDto } from '../models/create-checklist-template-dto';
import { CreatePropertiesChecklist } from '../models/create-properties-checklist';
import { Page } from '../models/page';
import { SortOrder } from '../models/sort-order';
import { UpdateChecklistCriteriaV1Dto } from '../models/update-checklist-criteria-v-1-dto';
import { UpdateChecklistTemplateDto } from '../models/update-checklist-template-dto';

@Injectable({ providedIn: 'root' })
export class ChecklistTemplatesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `checklistTemplates()` */
  static readonly ChecklistTemplatesPath = '/checklist-templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checklistTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  checklistTemplates$Response(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      mode?: ChecklistTemplateMode;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<ChecklistTemplate>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, ChecklistTemplatesService.ChecklistTemplatesPath, 'get');
    if (params) {
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('mode', params.mode, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<ChecklistTemplate>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checklistTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checklistTemplates(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      mode?: ChecklistTemplateMode;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<ChecklistTemplate>;
}> {
    return this.checklistTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<ChecklistTemplate>;
}>): Page & {
'result': Array<ChecklistTemplate>;
} => r.body)
    );
  }

  /** Path part for operation `createChecklistTemplate()` */
  static readonly CreateChecklistTemplatePath = '/checklist-templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createChecklistTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createChecklistTemplate$Response(
    params: {
      body: CreateChecklistTemplateDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplate>> {
    const rb = new RequestBuilder(this.rootUrl, ChecklistTemplatesService.CreateChecklistTemplatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplate>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createChecklistTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createChecklistTemplate(
    params: {
      body: CreateChecklistTemplateDto
    },
    context?: HttpContext
  ): Observable<ChecklistTemplate> {
    return this.createChecklistTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplate>): ChecklistTemplate => r.body)
    );
  }

  /** Path part for operation `deleteChecklistTemplate()` */
  static readonly DeleteChecklistTemplatePath = '/checklist-templates/{checklistTemplateId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteChecklistTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChecklistTemplate$Response(
    params: {
      checklistTemplateId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ChecklistTemplatesService.DeleteChecklistTemplatePath, 'delete');
    if (params) {
      rb.path('checklistTemplateId', params.checklistTemplateId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteChecklistTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChecklistTemplate(
    params: {
      checklistTemplateId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteChecklistTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateChecklistTemplate()` */
  static readonly UpdateChecklistTemplatePath = '/checklist-templates/{checklistTemplateId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateChecklistTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateChecklistTemplate$Response(
    params: {
      checklistTemplateId: number;
      body: UpdateChecklistTemplateDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, ChecklistTemplatesService.UpdateChecklistTemplatePath, 'patch');
    if (params) {
      rb.path('checklistTemplateId', params.checklistTemplateId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateChecklistTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateChecklistTemplate(
    params: {
      checklistTemplateId: number;
      body: UpdateChecklistTemplateDto
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.updateChecklistTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `copyChecklistTemplate()` */
  static readonly CopyChecklistTemplatePath = '/checklist-templates/{checklistTemplateId}/copy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyChecklistTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  copyChecklistTemplate$Response(
    params: {
      checklistTemplateId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, ChecklistTemplatesService.CopyChecklistTemplatePath, 'post');
    if (params) {
      rb.path('checklistTemplateId', params.checklistTemplateId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `copyChecklistTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  copyChecklistTemplate(
    params: {
      checklistTemplateId: number;
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.copyChecklistTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `checklistTemplateV1()` */
  static readonly ChecklistTemplateV1Path = '/checklist-templates/v1/{checklistTemplateId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checklistTemplateV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  checklistTemplateV1$Response(
    params: {
      checklistTemplateId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, ChecklistTemplatesService.ChecklistTemplateV1Path, 'get');
    if (params) {
      rb.path('checklistTemplateId', params.checklistTemplateId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checklistTemplateV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checklistTemplateV1(
    params: {
      checklistTemplateId: number;
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.checklistTemplateV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `addChecklistTemplateLevelV1()` */
  static readonly AddChecklistTemplateLevelV1Path = '/checklist-templates/v1/{checklistTemplateId}/levels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addChecklistTemplateLevelV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addChecklistTemplateLevelV1$Response(
    params: {
      checklistTemplateId: number;
      body: AddChecklistLevelV1Dto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, ChecklistTemplatesService.AddChecklistTemplateLevelV1Path, 'post');
    if (params) {
      rb.path('checklistTemplateId', params.checklistTemplateId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addChecklistTemplateLevelV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addChecklistTemplateLevelV1(
    params: {
      checklistTemplateId: number;
      body: AddChecklistLevelV1Dto
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.addChecklistTemplateLevelV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `deleteChecklistTemplateLevelV1()` */
  static readonly DeleteChecklistTemplateLevelV1Path = '/checklist-templates/v1/{checklistTemplateId}/levels/{levelId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteChecklistTemplateLevelV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChecklistTemplateLevelV1$Response(
    params: {
      checklistTemplateId: number;
      levelId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, ChecklistTemplatesService.DeleteChecklistTemplateLevelV1Path, 'delete');
    if (params) {
      rb.path('checklistTemplateId', params.checklistTemplateId, {});
      rb.path('levelId', params.levelId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteChecklistTemplateLevelV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChecklistTemplateLevelV1(
    params: {
      checklistTemplateId: number;
      levelId: number;
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.deleteChecklistTemplateLevelV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `updateChecklistTemplateLevelV1()` */
  static readonly UpdateChecklistTemplateLevelV1Path = '/checklist-templates/v1/{checklistTemplateId}/levels/{levelId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateChecklistTemplateLevelV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateChecklistTemplateLevelV1$Response(
    params: {
      checklistTemplateId: number;
      levelId: number;
      body: AddChecklistLevelV1Dto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, ChecklistTemplatesService.UpdateChecklistTemplateLevelV1Path, 'patch');
    if (params) {
      rb.path('checklistTemplateId', params.checklistTemplateId, {});
      rb.path('levelId', params.levelId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateChecklistTemplateLevelV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateChecklistTemplateLevelV1(
    params: {
      checklistTemplateId: number;
      levelId: number;
      body: AddChecklistLevelV1Dto
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.updateChecklistTemplateLevelV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `addChecklistTemplateCriteriaV1()` */
  static readonly AddChecklistTemplateCriteriaV1Path = '/checklist-templates/v1/{checklistTemplateId}/levels/{levelId}/criteria';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addChecklistTemplateCriteriaV1()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  addChecklistTemplateCriteriaV1$Response(
    params: {
      checklistTemplateId: number;
      levelId: number;
      body: AddChecklistCriteriaV1Dto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, ChecklistTemplatesService.AddChecklistTemplateCriteriaV1Path, 'post');
    if (params) {
      rb.path('checklistTemplateId', params.checklistTemplateId, {});
      rb.path('levelId', params.levelId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addChecklistTemplateCriteriaV1$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  addChecklistTemplateCriteriaV1(
    params: {
      checklistTemplateId: number;
      levelId: number;
      body: AddChecklistCriteriaV1Dto
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.addChecklistTemplateCriteriaV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `deleteChecklistTemplateCriteriaV1()` */
  static readonly DeleteChecklistTemplateCriteriaV1Path = '/checklist-templates/v1/{checklistTemplateId}/levels/{levelId}/criteria/{criteriaId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteChecklistTemplateCriteriaV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChecklistTemplateCriteriaV1$Response(
    params: {
      checklistTemplateId: number;
      levelId: number;
      criteriaId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, ChecklistTemplatesService.DeleteChecklistTemplateCriteriaV1Path, 'delete');
    if (params) {
      rb.path('checklistTemplateId', params.checklistTemplateId, {});
      rb.path('levelId', params.levelId, {});
      rb.path('criteriaId', params.criteriaId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteChecklistTemplateCriteriaV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChecklistTemplateCriteriaV1(
    params: {
      checklistTemplateId: number;
      levelId: number;
      criteriaId: number;
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.deleteChecklistTemplateCriteriaV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `updateChecklistTemplateCriteriaV1()` */
  static readonly UpdateChecklistTemplateCriteriaV1Path = '/checklist-templates/v1/{checklistTemplateId}/levels/{levelId}/criteria/{criteriaId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateChecklistTemplateCriteriaV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateChecklistTemplateCriteriaV1$Response(
    params: {
      checklistTemplateId: number;
      levelId: number;
      criteriaId: number;
      body: UpdateChecklistCriteriaV1Dto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, ChecklistTemplatesService.UpdateChecklistTemplateCriteriaV1Path, 'patch');
    if (params) {
      rb.path('checklistTemplateId', params.checklistTemplateId, {});
      rb.path('levelId', params.levelId, {});
      rb.path('criteriaId', params.criteriaId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateChecklistTemplateCriteriaV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateChecklistTemplateCriteriaV1(
    params: {
      checklistTemplateId: number;
      levelId: number;
      criteriaId: number;
      body: UpdateChecklistCriteriaV1Dto
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.updateChecklistTemplateCriteriaV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `uploadChecklistTemplateCriteriaAttachments()` */
  static readonly UploadChecklistTemplateCriteriaAttachmentsPath = '/checklist-templates/{checklistTemplateId}/levels/{levelId}/criteria/{criteriaId}/attachments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadChecklistTemplateCriteriaAttachments()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadChecklistTemplateCriteriaAttachments$Response(
    params: {
      checklistTemplateId: number;
      levelId: number;
      criteriaId: number;
      body: {
'attachments': Array<Blob>;
}
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, ChecklistTemplatesService.UploadChecklistTemplateCriteriaAttachmentsPath, 'post');
    if (params) {
      rb.path('checklistTemplateId', params.checklistTemplateId, {});
      rb.path('levelId', params.levelId, {});
      rb.path('criteriaId', params.criteriaId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadChecklistTemplateCriteriaAttachments$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadChecklistTemplateCriteriaAttachments(
    params: {
      checklistTemplateId: number;
      levelId: number;
      criteriaId: number;
      body: {
'attachments': Array<Blob>;
}
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.uploadChecklistTemplateCriteriaAttachments$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `deleteChecklistTemplateCriteriaAttachment()` */
  static readonly DeleteChecklistTemplateCriteriaAttachmentPath = '/checklist-templates/{checklistTemplateId}/levels/{levelId}/criteria/{criteriaId}/attachments/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteChecklistTemplateCriteriaAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChecklistTemplateCriteriaAttachment$Response(
    params: {
      checklistTemplateId: number;
      levelId: number;
      criteriaId: number;
      attachmentId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, ChecklistTemplatesService.DeleteChecklistTemplateCriteriaAttachmentPath, 'delete');
    if (params) {
      rb.path('checklistTemplateId', params.checklistTemplateId, {});
      rb.path('levelId', params.levelId, {});
      rb.path('criteriaId', params.criteriaId, {});
      rb.path('attachmentId', params.attachmentId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteChecklistTemplateCriteriaAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChecklistTemplateCriteriaAttachment(
    params: {
      checklistTemplateId: number;
      levelId: number;
      criteriaId: number;
      attachmentId: string;
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.deleteChecklistTemplateCriteriaAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `createPropertiesChecklistFromTemplate()` */
  static readonly CreatePropertiesChecklistFromTemplatePath = '/checklist-templates/{checklistTemplateId}/propertiesChecklist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPropertiesChecklistFromTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPropertiesChecklistFromTemplate$Response(
    params: {
      checklistTemplateId: number;
      body: CreatePropertiesChecklist
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ChecklistTemplatesService.CreatePropertiesChecklistFromTemplatePath, 'post');
    if (params) {
      rb.path('checklistTemplateId', params.checklistTemplateId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPropertiesChecklistFromTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPropertiesChecklistFromTemplate(
    params: {
      checklistTemplateId: number;
      body: CreatePropertiesChecklist
    },
    context?: HttpContext
  ): Observable<void> {
    return this.createPropertiesChecklistFromTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
