/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AddChecklistTaskCriteriasDto } from '../models/add-checklist-task-criterias-dto';
import { AddCustomTaskCriteriaDto } from '../models/add-custom-task-criteria-dto';
import { CopyChecklistToTaskDto } from '../models/copy-checklist-to-task-dto';
import { CreateTaskDto } from '../models/create-task-dto';
import { Page } from '../models/page';
import { SortOrder } from '../models/sort-order';
import { TaskCriteria } from '../models/task-criteria';
import { TaskInfo } from '../models/task-info';
import { UpdateTaskCriteriaDto } from '../models/update-task-criteria-dto';
import { UpdateTaskDto } from '../models/update-task-dto';
import { UpdateTaskStatus } from '../models/update-task-status';

@Injectable({ providedIn: 'root' })
export class TasksService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `tasks()` */
  static readonly TasksPath = '/tasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tasks()` instead.
   *
   * This method doesn't expect any request body.
   */
  tasks$Response(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;

    /**
     * Format "YYYY-MM-DD"
     */
      startDate?: string;

    /**
     * Format "YYYY-MM-DD"
     */
      endDate?: string;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<TaskInfo>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TasksService.TasksPath, 'get');
    if (params) {
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<TaskInfo>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tasks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tasks(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;

    /**
     * Format "YYYY-MM-DD"
     */
      startDate?: string;

    /**
     * Format "YYYY-MM-DD"
     */
      endDate?: string;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<TaskInfo>;
}> {
    return this.tasks$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<TaskInfo>;
}>): Page & {
'result': Array<TaskInfo>;
} => r.body)
    );
  }

  /** Path part for operation `createTask()` */
  static readonly CreateTaskPath = '/tasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTask()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTask$Response(
    params: {
      body: CreateTaskDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TaskInfo>> {
    const rb = new RequestBuilder(this.rootUrl, TasksService.CreateTaskPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskInfo>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTask$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTask(
    params: {
      body: CreateTaskDto
    },
    context?: HttpContext
  ): Observable<TaskInfo> {
    return this.createTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaskInfo>): TaskInfo => r.body)
    );
  }

  /** Path part for operation `findTask()` */
  static readonly FindTaskPath = '/tasks/{taskId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTask$Response(
    params: {
      taskId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TaskInfo>> {
    const rb = new RequestBuilder(this.rootUrl, TasksService.FindTaskPath, 'get');
    if (params) {
      rb.path('taskId', params.taskId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskInfo>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTask(
    params: {
      taskId: number;
    },
    context?: HttpContext
  ): Observable<TaskInfo> {
    return this.findTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaskInfo>): TaskInfo => r.body)
    );
  }

  /** Path part for operation `removeTask()` */
  static readonly RemoveTaskPath = '/tasks/{taskId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeTask$Response(
    params: {
      taskId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, TasksService.RemoveTaskPath, 'delete');
    if (params) {
      rb.path('taskId', params.taskId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeTask(
    params: {
      taskId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.removeTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateTask()` */
  static readonly UpdateTaskPath = '/tasks/{taskId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTask()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTask$Response(
    params: {
      taskId: number;
      body: UpdateTaskDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TaskInfo>> {
    const rb = new RequestBuilder(this.rootUrl, TasksService.UpdateTaskPath, 'patch');
    if (params) {
      rb.path('taskId', params.taskId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskInfo>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTask$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTask(
    params: {
      taskId: number;
      body: UpdateTaskDto
    },
    context?: HttpContext
  ): Observable<TaskInfo> {
    return this.updateTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaskInfo>): TaskInfo => r.body)
    );
  }

  /** Path part for operation `copyChecklistToTask()` */
  static readonly CopyChecklistToTaskPath = '/tasks/{taskId}/copyChecklistToTask';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyChecklistToTask()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyChecklistToTask$Response(
    params: {
      taskId: number;
      body: CopyChecklistToTaskDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TaskInfo>> {
    const rb = new RequestBuilder(this.rootUrl, TasksService.CopyChecklistToTaskPath, 'post');
    if (params) {
      rb.path('taskId', params.taskId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskInfo>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `copyChecklistToTask$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyChecklistToTask(
    params: {
      taskId: number;
      body: CopyChecklistToTaskDto
    },
    context?: HttpContext
  ): Observable<TaskInfo> {
    return this.copyChecklistToTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaskInfo>): TaskInfo => r.body)
    );
  }

  /** Path part for operation `addCustomTaskCriteria()` */
  static readonly AddCustomTaskCriteriaPath = '/tasks/{taskId}/customCriteria';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCustomTaskCriteria()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  addCustomTaskCriteria$Response(
    params: {
      taskId: number;
      body: AddCustomTaskCriteriaDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TaskInfo>> {
    const rb = new RequestBuilder(this.rootUrl, TasksService.AddCustomTaskCriteriaPath, 'post');
    if (params) {
      rb.path('taskId', params.taskId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskInfo>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCustomTaskCriteria$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  addCustomTaskCriteria(
    params: {
      taskId: number;
      body: AddCustomTaskCriteriaDto
    },
    context?: HttpContext
  ): Observable<TaskInfo> {
    return this.addCustomTaskCriteria$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaskInfo>): TaskInfo => r.body)
    );
  }

  /** Path part for operation `taskCriterias()` */
  static readonly TaskCriteriasPath = '/tasks/{taskId}/criterias';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taskCriterias()` instead.
   *
   * This method doesn't expect any request body.
   */
  taskCriterias$Response(
    params: {
      taskId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<TaskCriteria>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TasksService.TaskCriteriasPath, 'get');
    if (params) {
      rb.path('taskId', params.taskId, {});
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<TaskCriteria>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taskCriterias$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  taskCriterias(
    params: {
      taskId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<TaskCriteria>;
}> {
    return this.taskCriterias$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<TaskCriteria>;
}>): Page & {
'result': Array<TaskCriteria>;
} => r.body)
    );
  }

  /** Path part for operation `addChecklistTaskCriterias()` */
  static readonly AddChecklistTaskCriteriasPath = '/tasks/{taskId}/criterias';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addChecklistTaskCriterias()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addChecklistTaskCriterias$Response(
    params: {
      taskId: number;
      body: AddChecklistTaskCriteriasDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, TasksService.AddChecklistTaskCriteriasPath, 'post');
    if (params) {
      rb.path('taskId', params.taskId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addChecklistTaskCriterias$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addChecklistTaskCriterias(
    params: {
      taskId: number;
      body: AddChecklistTaskCriteriasDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.addChecklistTaskCriterias$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateTaskCriteria()` */
  static readonly UpdateTaskCriteriaPath = '/tasks/{taskId}/criterias/{criteriaId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTaskCriteria()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTaskCriteria$Response(
    params: {
      taskId: number;
      criteriaId: number;
      body: UpdateTaskCriteriaDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, TasksService.UpdateTaskCriteriaPath, 'patch');
    if (params) {
      rb.path('taskId', params.taskId, {});
      rb.path('criteriaId', params.criteriaId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTaskCriteria$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTaskCriteria(
    params: {
      taskId: number;
      criteriaId: number;
      body: UpdateTaskCriteriaDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateTaskCriteria$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `uploadTaskCriteriaAttachments()` */
  static readonly UploadTaskCriteriaAttachmentsPath = '/tasks/{taskId}/criterias/{criteriaId}/attachments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadTaskCriteriaAttachments()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadTaskCriteriaAttachments$Response(
    params: {
      taskId: number;
      criteriaId: number;
      body: {
'attachments': Array<Blob>;
}
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TaskCriteria>> {
    const rb = new RequestBuilder(this.rootUrl, TasksService.UploadTaskCriteriaAttachmentsPath, 'post');
    if (params) {
      rb.path('taskId', params.taskId, {});
      rb.path('criteriaId', params.criteriaId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskCriteria>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadTaskCriteriaAttachments$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadTaskCriteriaAttachments(
    params: {
      taskId: number;
      criteriaId: number;
      body: {
'attachments': Array<Blob>;
}
    },
    context?: HttpContext
  ): Observable<TaskCriteria> {
    return this.uploadTaskCriteriaAttachments$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaskCriteria>): TaskCriteria => r.body)
    );
  }

  /** Path part for operation `deleteTaskCriteriaAttachment()` */
  static readonly DeleteTaskCriteriaAttachmentPath = '/tasks/{taskId}/criterias/{criteriaId}/attachments/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTaskCriteriaAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTaskCriteriaAttachment$Response(
    params: {
      taskId: number;
      criteriaId: number;
      attachmentId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TaskCriteria>> {
    const rb = new RequestBuilder(this.rootUrl, TasksService.DeleteTaskCriteriaAttachmentPath, 'delete');
    if (params) {
      rb.path('taskId', params.taskId, {});
      rb.path('criteriaId', params.criteriaId, {});
      rb.path('attachmentId', params.attachmentId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskCriteria>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTaskCriteriaAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTaskCriteriaAttachment(
    params: {
      taskId: number;
      criteriaId: number;
      attachmentId: string;
    },
    context?: HttpContext
  ): Observable<TaskCriteria> {
    return this.deleteTaskCriteriaAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaskCriteria>): TaskCriteria => r.body)
    );
  }

  /** Path part for operation `deleteTaskCriteria()` */
  static readonly DeleteTaskCriteriaPath = '/tasks/{taskId}/criteria/{criteriaId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTaskCriteria()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTaskCriteria$Response(
    params: {
      taskId: number;
      criteriaId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TaskInfo>> {
    const rb = new RequestBuilder(this.rootUrl, TasksService.DeleteTaskCriteriaPath, 'delete');
    if (params) {
      rb.path('taskId', params.taskId, {});
      rb.path('criteriaId', params.criteriaId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskInfo>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTaskCriteria$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTaskCriteria(
    params: {
      taskId: number;
      criteriaId: number;
    },
    context?: HttpContext
  ): Observable<TaskInfo> {
    return this.deleteTaskCriteria$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaskInfo>): TaskInfo => r.body)
    );
  }

  /** Path part for operation `activateTask()` */
  static readonly ActivateTaskPath = '/tasks/{taskId}/activate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateTask()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateTask$Response(
    params: {
      taskId: number;
      body: UpdateTaskStatus
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TaskInfo>> {
    const rb = new RequestBuilder(this.rootUrl, TasksService.ActivateTaskPath, 'put');
    if (params) {
      rb.path('taskId', params.taskId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskInfo>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activateTask$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateTask(
    params: {
      taskId: number;
      body: UpdateTaskStatus
    },
    context?: HttpContext
  ): Observable<TaskInfo> {
    return this.activateTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaskInfo>): TaskInfo => r.body)
    );
  }

}
