/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AddChecklistCriteriaV1Dto } from '../models/add-checklist-criteria-v-1-dto';
import { AddChecklistLevelV1Dto } from '../models/add-checklist-level-v-1-dto';
import { AddChecklistsToPropertyDto } from '../models/add-checklists-to-property-dto';
import { AssignAttachmentsToProperty } from '../models/assign-attachments-to-property';
import { AssignUsersToProperty } from '../models/assign-users-to-property';
import { Attachment } from '../models/attachment';
import { ChecklistCriteria } from '../models/checklist-criteria';
import { ChecklistInfo } from '../models/checklist-info';
import { ChecklistTemplateDetailsV1 } from '../models/checklist-template-details-v-1';
import { CreatePropertyDto } from '../models/create-property-dto';
import { InspectionInfo } from '../models/inspection-info';
import { Page } from '../models/page';
import { Property } from '../models/property';
import { PropertyAttachmentMode } from '../models/property-attachment-mode';
import { PropertyUserMode } from '../models/property-user-mode';
import { SortOrder } from '../models/sort-order';
import { TaskInfo } from '../models/task-info';
import { UnassignUsersFromProperty } from '../models/unassign-users-from-property';
import { UpdateChecklistCriteriaV1Dto } from '../models/update-checklist-criteria-v-1-dto';
import { UpdatePropertyChecklistDto } from '../models/update-property-checklist-dto';
import { UpdatePropertyDto } from '../models/update-property-dto';
import { UploadPropertyImageDto } from '../models/upload-property-image-dto';
import { User } from '../models/user';

@Injectable({ providedIn: 'root' })
export class PropertiesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `findAllProperties()` */
  static readonly FindAllPropertiesPath = '/properties';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllProperties()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllProperties$Response(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<Property>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.FindAllPropertiesPath, 'get');
    if (params) {
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<Property>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllProperties$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllProperties(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<Property>;
}> {
    return this.findAllProperties$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<Property>;
}>): Page & {
'result': Array<Property>;
} => r.body)
    );
  }

  /** Path part for operation `createProperty()` */
  static readonly CreatePropertyPath = '/properties';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createProperty()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createProperty$Response(
    params: {
      body: CreatePropertyDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Property>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.CreatePropertyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Property>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createProperty$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createProperty(
    params: {
      body: CreatePropertyDto
    },
    context?: HttpContext
  ): Observable<Property> {
    return this.createProperty$Response(params, context).pipe(
      map((r: StrictHttpResponse<Property>): Property => r.body)
    );
  }

  /** Path part for operation `propertyCsvImport()` */
  static readonly PropertyCsvImportPath = '/properties/import/csv';

  /**
   * Only supports files with mimetype `text/csv`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `propertyCsvImport()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  propertyCsvImport$Response(
    params: {
      body: {
'csv': Blob;
}
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.PropertyCsvImportPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Only supports files with mimetype `text/csv`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `propertyCsvImport$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  propertyCsvImport(
    params: {
      body: {
'csv': Blob;
}
    },
    context?: HttpContext
  ): Observable<void> {
    return this.propertyCsvImport$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `findProperty()` */
  static readonly FindPropertyPath = '/properties/{propertyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findProperty()` instead.
   *
   * This method doesn't expect any request body.
   */
  findProperty$Response(
    params: {
      propertyId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Property>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.FindPropertyPath, 'get');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Property>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findProperty$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findProperty(
    params: {
      propertyId: number;
    },
    context?: HttpContext
  ): Observable<Property> {
    return this.findProperty$Response(params, context).pipe(
      map((r: StrictHttpResponse<Property>): Property => r.body)
    );
  }

  /** Path part for operation `updateProperty()` */
  static readonly UpdatePropertyPath = '/properties/{propertyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProperty()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProperty$Response(
    params: {
      propertyId: number;
      body: UpdatePropertyDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Property>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.UpdatePropertyPath, 'patch');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Property>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateProperty$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProperty(
    params: {
      propertyId: number;
      body: UpdatePropertyDto
    },
    context?: HttpContext
  ): Observable<Property> {
    return this.updateProperty$Response(params, context).pipe(
      map((r: StrictHttpResponse<Property>): Property => r.body)
    );
  }

  /** Path part for operation `findPropertyUsers()` */
  static readonly FindPropertyUsersPath = '/properties/{propertyId}/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findPropertyUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  findPropertyUsers$Response(
    params: {
      propertyId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      mode?: PropertyUserMode;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<User>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.FindPropertyUsersPath, 'get');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('mode', params.mode, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<User>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findPropertyUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findPropertyUsers(
    params: {
      propertyId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      mode?: PropertyUserMode;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<User>;
}> {
    return this.findPropertyUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<User>;
}>): Page & {
'result': Array<User>;
} => r.body)
    );
  }

  /** Path part for operation `assignUsersToProperty()` */
  static readonly AssignUsersToPropertyPath = '/properties/{propertyId}/users/assign';

  /**
   * Assigns users to the property. Users will see this property in the app.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignUsersToProperty()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignUsersToProperty$Response(
    params: {
      propertyId: number;
      body: AssignUsersToProperty
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.AssignUsersToPropertyPath, 'put');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Assigns users to the property. Users will see this property in the app.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignUsersToProperty$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignUsersToProperty(
    params: {
      propertyId: number;
      body: AssignUsersToProperty
    },
    context?: HttpContext
  ): Observable<number> {
    return this.assignUsersToProperty$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `unassignUsersToProperty()` */
  static readonly UnassignUsersToPropertyPath = '/properties/{propertyId}/users/unassign';

  /**
   * Unassign the users from the property. This also deletes open inspections and tasks assigned to the users.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unassignUsersToProperty()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unassignUsersToProperty$Response(
    params: {
      propertyId: number;
      body: UnassignUsersFromProperty
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.UnassignUsersToPropertyPath, 'put');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Unassign the users from the property. This also deletes open inspections and tasks assigned to the users.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unassignUsersToProperty$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unassignUsersToProperty(
    params: {
      propertyId: number;
      body: UnassignUsersFromProperty
    },
    context?: HttpContext
  ): Observable<number> {
    return this.unassignUsersToProperty$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `setPropertyImage()` */
  static readonly SetPropertyImagePath = '/properties/{propertyId}/image';

  /**
   * Only supports files with mimetype `image/*`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setPropertyImage()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  setPropertyImage$Response(
    params: {
      propertyId: number;
      body: UploadPropertyImageDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Property>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.SetPropertyImagePath, 'put');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Property>;
      })
    );
  }

  /**
   * Only supports files with mimetype `image/*`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setPropertyImage$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  setPropertyImage(
    params: {
      propertyId: number;
      body: UploadPropertyImageDto
    },
    context?: HttpContext
  ): Observable<Property> {
    return this.setPropertyImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<Property>): Property => r.body)
    );
  }

  /** Path part for operation `removePropertyImage()` */
  static readonly RemovePropertyImagePath = '/properties/{propertyId}/image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removePropertyImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  removePropertyImage$Response(
    params: {
      propertyId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Property>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.RemovePropertyImagePath, 'delete');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Property>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removePropertyImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removePropertyImage(
    params: {
      propertyId: number;
    },
    context?: HttpContext
  ): Observable<Property> {
    return this.removePropertyImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<Property>): Property => r.body)
    );
  }

  /** Path part for operation `pagePropertyInspections()` */
  static readonly PagePropertyInspectionsPath = '/properties/{propertyId}/inspections';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pagePropertyInspections()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagePropertyInspections$Response(
    params: {
      propertyId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;

    /**
     * Format "YYYY-MM-DD"
     */
      startDate?: string;

    /**
     * Format "YYYY-MM-DD"
     */
      endDate?: string;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<InspectionInfo>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.PagePropertyInspectionsPath, 'get');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<InspectionInfo>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pagePropertyInspections$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagePropertyInspections(
    params: {
      propertyId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;

    /**
     * Format "YYYY-MM-DD"
     */
      startDate?: string;

    /**
     * Format "YYYY-MM-DD"
     */
      endDate?: string;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<InspectionInfo>;
}> {
    return this.pagePropertyInspections$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<InspectionInfo>;
}>): Page & {
'result': Array<InspectionInfo>;
} => r.body)
    );
  }

  /** Path part for operation `pagePropertyTasks()` */
  static readonly PagePropertyTasksPath = '/properties/{propertyId}/tasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pagePropertyTasks()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagePropertyTasks$Response(
    params: {
      propertyId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;

    /**
     * Format "YYYY-MM-DD"
     */
      startDate?: string;

    /**
     * Format "YYYY-MM-DD"
     */
      endDate?: string;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<TaskInfo>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.PagePropertyTasksPath, 'get');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<TaskInfo>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pagePropertyTasks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagePropertyTasks(
    params: {
      propertyId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;

    /**
     * Format "YYYY-MM-DD"
     */
      startDate?: string;

    /**
     * Format "YYYY-MM-DD"
     */
      endDate?: string;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<TaskInfo>;
}> {
    return this.pagePropertyTasks$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<TaskInfo>;
}>): Page & {
'result': Array<TaskInfo>;
} => r.body)
    );
  }

  /** Path part for operation `pagePropertyChecklists()` */
  static readonly PagePropertyChecklistsPath = '/properties/{propertyId}/checklists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pagePropertyChecklists()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagePropertyChecklists$Response(
    params: {
      propertyId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<ChecklistInfo>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.PagePropertyChecklistsPath, 'get');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<ChecklistInfo>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pagePropertyChecklists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagePropertyChecklists(
    params: {
      propertyId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<ChecklistInfo>;
}> {
    return this.pagePropertyChecklists$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<ChecklistInfo>;
}>): Page & {
'result': Array<ChecklistInfo>;
} => r.body)
    );
  }

  /** Path part for operation `addChecklistsToProperty()` */
  static readonly AddChecklistsToPropertyPath = '/properties/{propertyId}/checklists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addChecklistsToProperty()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addChecklistsToProperty$Response(
    params: {
      propertyId: number;
      body: AddChecklistsToPropertyDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.AddChecklistsToPropertyPath, 'post');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addChecklistsToProperty$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addChecklistsToProperty(
    params: {
      propertyId: number;
      body: AddChecklistsToPropertyDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.addChecklistsToProperty$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `propertyChecklistV1()` */
  static readonly PropertyChecklistV1Path = '/properties/{propertyId}/checklists-v1/{checklistId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `propertyChecklistV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  propertyChecklistV1$Response(
    params: {
      propertyId: number;
      checklistId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.PropertyChecklistV1Path, 'get');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.path('checklistId', params.checklistId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `propertyChecklistV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  propertyChecklistV1(
    params: {
      propertyId: number;
      checklistId: number;
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.propertyChecklistV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `addPropertyChecklistLevelV1()` */
  static readonly AddPropertyChecklistLevelV1Path = '/properties/{propertyId}/checklists-v1/{checklistId}/levels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPropertyChecklistLevelV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPropertyChecklistLevelV1$Response(
    params: {
      propertyId: number;
      checklistId: number;
      body: AddChecklistLevelV1Dto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.AddPropertyChecklistLevelV1Path, 'post');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.path('checklistId', params.checklistId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addPropertyChecklistLevelV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPropertyChecklistLevelV1(
    params: {
      propertyId: number;
      checklistId: number;
      body: AddChecklistLevelV1Dto
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.addPropertyChecklistLevelV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `deletePropertyChecklistLevelV1()` */
  static readonly DeletePropertyChecklistLevelV1Path = '/properties/{propertyId}/checklists-v1/{checklistId}/levels/{levelId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePropertyChecklistLevelV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePropertyChecklistLevelV1$Response(
    params: {
      propertyId: number;
      checklistId: number;
      levelId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.DeletePropertyChecklistLevelV1Path, 'delete');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.path('checklistId', params.checklistId, {});
      rb.path('levelId', params.levelId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePropertyChecklistLevelV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePropertyChecklistLevelV1(
    params: {
      propertyId: number;
      checklistId: number;
      levelId: number;
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.deletePropertyChecklistLevelV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `updatePropertyChecklistLevelV1()` */
  static readonly UpdatePropertyChecklistLevelV1Path = '/properties/{propertyId}/checklists-v1/{checklistId}/levels/{levelId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePropertyChecklistLevelV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePropertyChecklistLevelV1$Response(
    params: {
      propertyId: number;
      checklistId: number;
      levelId: number;
      body: AddChecklistLevelV1Dto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.UpdatePropertyChecklistLevelV1Path, 'patch');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.path('checklistId', params.checklistId, {});
      rb.path('levelId', params.levelId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePropertyChecklistLevelV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePropertyChecklistLevelV1(
    params: {
      propertyId: number;
      checklistId: number;
      levelId: number;
      body: AddChecklistLevelV1Dto
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.updatePropertyChecklistLevelV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `addPropertyChecklistCriteriaV1()` */
  static readonly AddPropertyChecklistCriteriaV1Path = '/properties/{propertyId}/checklists-v1/{checklistId}/levels/{levelId}/criteria';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPropertyChecklistCriteriaV1()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  addPropertyChecklistCriteriaV1$Response(
    params: {
      propertyId: number;
      checklistId: number;
      levelId: number;
      body: AddChecklistCriteriaV1Dto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.AddPropertyChecklistCriteriaV1Path, 'post');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.path('checklistId', params.checklistId, {});
      rb.path('levelId', params.levelId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addPropertyChecklistCriteriaV1$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  addPropertyChecklistCriteriaV1(
    params: {
      propertyId: number;
      checklistId: number;
      levelId: number;
      body: AddChecklistCriteriaV1Dto
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.addPropertyChecklistCriteriaV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `deleteChecklistCriteriaV1()` */
  static readonly DeleteChecklistCriteriaV1Path = '/properties/{propertyId}/checklists-v1/{checklistId}/levels/{levelId}/criteria/{criteriaId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteChecklistCriteriaV1()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChecklistCriteriaV1$Response(
    params: {
      propertyId: number;
      checklistId: number;
      levelId: number;
      criteriaId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.DeleteChecklistCriteriaV1Path, 'delete');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.path('checklistId', params.checklistId, {});
      rb.path('levelId', params.levelId, {});
      rb.path('criteriaId', params.criteriaId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteChecklistCriteriaV1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChecklistCriteriaV1(
    params: {
      propertyId: number;
      checklistId: number;
      levelId: number;
      criteriaId: number;
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.deleteChecklistCriteriaV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `updateChecklistCriteriaV1()` */
  static readonly UpdateChecklistCriteriaV1Path = '/properties/{propertyId}/checklists-v1/{checklistId}/levels/{levelId}/criteria/{criteriaId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateChecklistCriteriaV1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateChecklistCriteriaV1$Response(
    params: {
      propertyId: number;
      checklistId: number;
      levelId: number;
      criteriaId: number;
      body: UpdateChecklistCriteriaV1Dto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.UpdateChecklistCriteriaV1Path, 'patch');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.path('checklistId', params.checklistId, {});
      rb.path('levelId', params.levelId, {});
      rb.path('criteriaId', params.criteriaId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateChecklistCriteriaV1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateChecklistCriteriaV1(
    params: {
      propertyId: number;
      checklistId: number;
      levelId: number;
      criteriaId: number;
      body: UpdateChecklistCriteriaV1Dto
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.updateChecklistCriteriaV1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `deleteChecklistFromProperty()` */
  static readonly DeleteChecklistFromPropertyPath = '/properties/{propertyId}/checklists/{checklistId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteChecklistFromProperty()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChecklistFromProperty$Response(
    params: {
      propertyId: number;
      checklistId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.DeleteChecklistFromPropertyPath, 'delete');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.path('checklistId', params.checklistId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteChecklistFromProperty$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChecklistFromProperty(
    params: {
      propertyId: number;
      checklistId: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteChecklistFromProperty$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updatePropertyChecklist()` */
  static readonly UpdatePropertyChecklistPath = '/properties/{propertyId}/checklists/{checklistId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePropertyChecklist()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePropertyChecklist$Response(
    params: {
      propertyId: number;
      checklistId: number;
      body: UpdatePropertyChecklistDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.UpdatePropertyChecklistPath, 'patch');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.path('checklistId', params.checklistId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePropertyChecklist$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePropertyChecklist(
    params: {
      propertyId: number;
      checklistId: number;
      body: UpdatePropertyChecklistDto
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.updatePropertyChecklist$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `uploadChecklistCriteriaAttachments()` */
  static readonly UploadChecklistCriteriaAttachmentsPath = '/properties/{propertyId}/checklists/{checklistId}/levels/{levelId}/criteria/{criteriaId}/attachments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadChecklistCriteriaAttachments()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadChecklistCriteriaAttachments$Response(
    params: {
      propertyId: number;
      checklistId: number;
      levelId: number;
      criteriaId: number;
      body: {
'attachments': Array<Blob>;
}
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.UploadChecklistCriteriaAttachmentsPath, 'post');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.path('checklistId', params.checklistId, {});
      rb.path('levelId', params.levelId, {});
      rb.path('criteriaId', params.criteriaId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadChecklistCriteriaAttachments$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadChecklistCriteriaAttachments(
    params: {
      propertyId: number;
      checklistId: number;
      levelId: number;
      criteriaId: number;
      body: {
'attachments': Array<Blob>;
}
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.uploadChecklistCriteriaAttachments$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `deleteChecklistCriteriaAttachment()` */
  static readonly DeleteChecklistCriteriaAttachmentPath = '/properties/{propertyId}/checklists/{checklistId}/levels/{levelId}/criteria/{criteriaId}/attachments/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteChecklistCriteriaAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChecklistCriteriaAttachment$Response(
    params: {
      propertyId: number;
      checklistId: number;
      levelId: number;
      criteriaId: number;
      attachmentId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChecklistTemplateDetailsV1>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.DeleteChecklistCriteriaAttachmentPath, 'delete');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.path('checklistId', params.checklistId, {});
      rb.path('levelId', params.levelId, {});
      rb.path('criteriaId', params.criteriaId, {});
      rb.path('attachmentId', params.attachmentId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChecklistTemplateDetailsV1>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteChecklistCriteriaAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChecklistCriteriaAttachment(
    params: {
      propertyId: number;
      checklistId: number;
      levelId: number;
      criteriaId: number;
      attachmentId: string;
    },
    context?: HttpContext
  ): Observable<ChecklistTemplateDetailsV1> {
    return this.deleteChecklistCriteriaAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChecklistTemplateDetailsV1>): ChecklistTemplateDetailsV1 => r.body)
    );
  }

  /** Path part for operation `pagePropertyChecklistCriterias()` */
  static readonly PagePropertyChecklistCriteriasPath = '/properties/{propertyId}/checklistCriterias';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pagePropertyChecklistCriterias()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagePropertyChecklistCriterias$Response(
    params: {
      propertyId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<ChecklistCriteria>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.PagePropertyChecklistCriteriasPath, 'get');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<ChecklistCriteria>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pagePropertyChecklistCriterias$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pagePropertyChecklistCriterias(
    params: {
      propertyId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<ChecklistCriteria>;
}> {
    return this.pagePropertyChecklistCriterias$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<ChecklistCriteria>;
}>): Page & {
'result': Array<ChecklistCriteria>;
} => r.body)
    );
  }

  /** Path part for operation `listPropertyAttachments()` */
  static readonly ListPropertyAttachmentsPath = '/properties/{propertyId}/attachments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listPropertyAttachments()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPropertyAttachments$Response(
    params: {
      propertyId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      mode?: PropertyAttachmentMode;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<Attachment>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.ListPropertyAttachmentsPath, 'get');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('mode', params.mode, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<Attachment>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listPropertyAttachments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPropertyAttachments(
    params: {
      propertyId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      mode?: PropertyAttachmentMode;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<Attachment>;
}> {
    return this.listPropertyAttachments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<Attachment>;
}>): Page & {
'result': Array<Attachment>;
} => r.body)
    );
  }

  /** Path part for operation `assignAttachmentsToProperty()` */
  static readonly AssignAttachmentsToPropertyPath = '/properties/{propertyId}/attachments/assign';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignAttachmentsToProperty()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignAttachmentsToProperty$Response(
    params: {
      propertyId: number;
      body: AssignAttachmentsToProperty
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.AssignAttachmentsToPropertyPath, 'put');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignAttachmentsToProperty$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignAttachmentsToProperty(
    params: {
      propertyId: number;
      body: AssignAttachmentsToProperty
    },
    context?: HttpContext
  ): Observable<void> {
    return this.assignAttachmentsToProperty$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `unassignAttachmentsFromProperty()` */
  static readonly UnassignAttachmentsFromPropertyPath = '/properties/{propertyId}/attachments/unassign';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unassignAttachmentsFromProperty()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unassignAttachmentsFromProperty$Response(
    params: {
      propertyId: number;
      body: AssignAttachmentsToProperty
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PropertiesService.UnassignAttachmentsFromPropertyPath, 'put');
    if (params) {
      rb.path('propertyId', params.propertyId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unassignAttachmentsFromProperty$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unassignAttachmentsFromProperty(
    params: {
      propertyId: number;
      body: AssignAttachmentsToProperty
    },
    context?: HttpContext
  ): Observable<void> {
    return this.unassignAttachmentsFromProperty$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
