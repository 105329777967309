/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { Category } from '../models/category';
import { Plan } from '../models/plan';
import { Priority } from '../models/priority';

@Injectable({ providedIn: 'root' })
export class LookupService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `categories()` */
  static readonly CategoriesPath = '/lookup/categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `categories()` instead.
   *
   * This method doesn't expect any request body.
   */
  categories$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<Category>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupService.CategoriesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Category>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `categories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  categories(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<Category>> {
    return this.categories$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Category>>): Array<Category> => r.body)
    );
  }

  /** Path part for operation `priorities()` */
  static readonly PrioritiesPath = '/lookup/priorities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priorities()` instead.
   *
   * This method doesn't expect any request body.
   */
  priorities$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<Priority>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupService.PrioritiesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Priority>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priorities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priorities(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<Priority>> {
    return this.priorities$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Priority>>): Array<Priority> => r.body)
    );
  }

  /** Path part for operation `pricingPlans()` */
  static readonly PricingPlansPath = '/lookup/pricing-plans';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pricingPlans()` instead.
   *
   * This method doesn't expect any request body.
   */
  pricingPlans$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<Plan>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupService.PricingPlansPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Plan>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pricingPlans$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pricingPlans(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<Plan>> {
    return this.pricingPlans$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Plan>>): Array<Plan> => r.body)
    );
  }

}
