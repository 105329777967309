/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { Auth } from '../models/auth';
import { BillingPeriod } from '../models/billing-period';
import { CreateManagerDto } from '../models/create-manager-dto';
import { ExtendTrialPeriodDto } from '../models/extend-trial-period-dto';
import { Manager } from '../models/manager';
import { ManagerUser } from '../models/manager-user';
import { Page } from '../models/page';
import { Property } from '../models/property';
import { SortOrder } from '../models/sort-order';
import { UpdateBillingPeriodDto } from '../models/update-billing-period-dto';
import { UpdateManagerDto } from '../models/update-manager-dto';
import { UpdateManagerStatus } from '../models/update-manager-status';
import { Usage } from '../models/usage';
import { User } from '../models/user';

@Injectable({ providedIn: 'root' })
export class ManagersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `findAllManagers()` */
  static readonly FindAllManagersPath = '/managers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllManagers()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllManagers$Response(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<Manager>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, ManagersService.FindAllManagersPath, 'get');
    if (params) {
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<Manager>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllManagers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllManagers(
    params?: {

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<Manager>;
}> {
    return this.findAllManagers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<Manager>;
}>): Page & {
'result': Array<Manager>;
} => r.body)
    );
  }

  /** Path part for operation `create()` */
  static readonly CreatePath = '/managers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(
    params: {
      body: CreateManagerDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ManagersService.CreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(
    params: {
      body: CreateManagerDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `profile()` */
  static readonly ProfilePath = '/managers/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `profile()` instead.
   *
   * This method doesn't expect any request body.
   */
  profile$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ManagerUser>> {
    const rb = new RequestBuilder(this.rootUrl, ManagersService.ProfilePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManagerUser>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `profile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  profile(
    params?: {
    },
    context?: HttpContext
  ): Observable<ManagerUser> {
    return this.profile$Response(params, context).pipe(
      map((r: StrictHttpResponse<ManagerUser>): ManagerUser => r.body)
    );
  }

  /** Path part for operation `uploadLogo()` */
  static readonly UploadLogoPath = '/managers/profile/logo';

  /**
   * Only supports files with mimetype `image/*`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadLogo()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadLogo$Response(
    params: {
      body: {
'logo'?: Blob;
}
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ManagerUser>> {
    const rb = new RequestBuilder(this.rootUrl, ManagersService.UploadLogoPath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManagerUser>;
      })
    );
  }

  /**
   * Only supports files with mimetype `image/*`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadLogo$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadLogo(
    params: {
      body: {
'logo'?: Blob;
}
    },
    context?: HttpContext
  ): Observable<ManagerUser> {
    return this.uploadLogo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ManagerUser>): ManagerUser => r.body)
    );
  }

  /** Path part for operation `deleteLogo()` */
  static readonly DeleteLogoPath = '/managers/profile/logo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteLogo()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLogo$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ManagerUser>> {
    const rb = new RequestBuilder(this.rootUrl, ManagersService.DeleteLogoPath, 'delete');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManagerUser>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteLogo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLogo(
    params?: {
    },
    context?: HttpContext
  ): Observable<ManagerUser> {
    return this.deleteLogo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ManagerUser>): ManagerUser => r.body)
    );
  }

  /** Path part for operation `findManager()` */
  static readonly FindManagerPath = '/managers/{managerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findManager()` instead.
   *
   * This method doesn't expect any request body.
   */
  findManager$Response(
    params: {
      managerId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Manager>> {
    const rb = new RequestBuilder(this.rootUrl, ManagersService.FindManagerPath, 'get');
    if (params) {
      rb.path('managerId', params.managerId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Manager>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findManager$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findManager(
    params: {
      managerId: number;
    },
    context?: HttpContext
  ): Observable<Manager> {
    return this.findManager$Response(params, context).pipe(
      map((r: StrictHttpResponse<Manager>): Manager => r.body)
    );
  }

  /** Path part for operation `update()` */
  static readonly UpdatePath = '/managers/{managerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(
    params: {
      managerId: number;
      body: UpdateManagerDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Manager>> {
    const rb = new RequestBuilder(this.rootUrl, ManagersService.UpdatePath, 'patch');
    if (params) {
      rb.path('managerId', params.managerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Manager>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(
    params: {
      managerId: number;
      body: UpdateManagerDto
    },
    context?: HttpContext
  ): Observable<Manager> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<Manager>): Manager => r.body)
    );
  }

  /** Path part for operation `findManagerUsers()` */
  static readonly FindManagerUsersPath = '/managers/{managerId}/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findManagerUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  findManagerUsers$Response(
    params: {
      managerId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<User>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, ManagersService.FindManagerUsersPath, 'get');
    if (params) {
      rb.path('managerId', params.managerId, {});
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<User>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findManagerUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findManagerUsers(
    params: {
      managerId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<User>;
}> {
    return this.findManagerUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<User>;
}>): Page & {
'result': Array<User>;
} => r.body)
    );
  }

  /** Path part for operation `findManagerProperties()` */
  static readonly FindManagerPropertiesPath = '/managers/{managerId}/properties';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findManagerProperties()` instead.
   *
   * This method doesn't expect any request body.
   */
  findManagerProperties$Response(
    params: {
      managerId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<Property>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, ManagersService.FindManagerPropertiesPath, 'get');
    if (params) {
      rb.path('managerId', params.managerId, {});
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<Property>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findManagerProperties$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findManagerProperties(
    params: {
      managerId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<Property>;
}> {
    return this.findManagerProperties$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<Property>;
}>): Page & {
'result': Array<Property>;
} => r.body)
    );
  }

  /** Path part for operation `updateManagerStatus()` */
  static readonly UpdateManagerStatusPath = '/managers/{managerId}/status';

  /**
   * Changes the manager status and deactivates all tasks when a manager will be disabled.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateManagerStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateManagerStatus$Response(
    params: {
      managerId: number;
      body: UpdateManagerStatus
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Manager>> {
    const rb = new RequestBuilder(this.rootUrl, ManagersService.UpdateManagerStatusPath, 'put');
    if (params) {
      rb.path('managerId', params.managerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Manager>;
      })
    );
  }

  /**
   * Changes the manager status and deactivates all tasks when a manager will be disabled.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateManagerStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateManagerStatus(
    params: {
      managerId: number;
      body: UpdateManagerStatus
    },
    context?: HttpContext
  ): Observable<Manager> {
    return this.updateManagerStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<Manager>): Manager => r.body)
    );
  }

  /** Path part for operation `impersonate()` */
  static readonly ImpersonatePath = '/managers/{managerId}/impersonate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impersonate()` instead.
   *
   * This method doesn't expect any request body.
   */
  impersonate$Response(
    params: {
      managerId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Auth>> {
    const rb = new RequestBuilder(this.rootUrl, ManagersService.ImpersonatePath, 'post');
    if (params) {
      rb.path('managerId', params.managerId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Auth>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impersonate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  impersonate(
    params: {
      managerId: number;
    },
    context?: HttpContext
  ): Observable<Auth> {
    return this.impersonate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Auth>): Auth => r.body)
    );
  }

  /** Path part for operation `managerUsages()` */
  static readonly ManagerUsagesPath = '/managers/{managerId}/usages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `managerUsages()` instead.
   *
   * This method doesn't expect any request body.
   */
  managerUsages$Response(
    params: {
      managerId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Page & {
'result': Array<Usage>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, ManagersService.ManagerUsagesPath, 'get');
    if (params) {
      rb.path('managerId', params.managerId, {});
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('searchQuery', params.searchQuery, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page & {
        'result': Array<Usage>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `managerUsages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  managerUsages(
    params: {
      managerId: number;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortColumn?: string;

    /**
     * Use sort instead
     *
     * @deprecated
     */
      sortOrder?: SortOrder;

    /**
     * Specify sorting: column.sortOrder e.g. id.desc
     */
      sort?: Array<string>;
      page?: number;
      size?: number;
      searchQuery?: string;
    },
    context?: HttpContext
  ): Observable<Page & {
'result': Array<Usage>;
}> {
    return this.managerUsages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Page & {
'result': Array<Usage>;
}>): Page & {
'result': Array<Usage>;
} => r.body)
    );
  }

  /** Path part for operation `updateBillingPeriod()` */
  static readonly UpdateBillingPeriodPath = '/managers/{managerId}/usages/{billingPeriodId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBillingPeriod()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBillingPeriod$Response(
    params: {
      managerId: number;
      billingPeriodId: string;
      body: UpdateBillingPeriodDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BillingPeriod>> {
    const rb = new RequestBuilder(this.rootUrl, ManagersService.UpdateBillingPeriodPath, 'put');
    if (params) {
      rb.path('managerId', params.managerId, {});
      rb.path('billingPeriodId', params.billingPeriodId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BillingPeriod>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBillingPeriod$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBillingPeriod(
    params: {
      managerId: number;
      billingPeriodId: string;
      body: UpdateBillingPeriodDto
    },
    context?: HttpContext
  ): Observable<BillingPeriod> {
    return this.updateBillingPeriod$Response(params, context).pipe(
      map((r: StrictHttpResponse<BillingPeriod>): BillingPeriod => r.body)
    );
  }

  /** Path part for operation `extendTrialPeriod()` */
  static readonly ExtendTrialPeriodPath = '/managers/{managerId}/usages/{billingPeriodId}/extendTrial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `extendTrialPeriod()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  extendTrialPeriod$Response(
    params: {
      managerId: number;
      billingPeriodId: string;
      body: ExtendTrialPeriodDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BillingPeriod>> {
    const rb = new RequestBuilder(this.rootUrl, ManagersService.ExtendTrialPeriodPath, 'put');
    if (params) {
      rb.path('managerId', params.managerId, {});
      rb.path('billingPeriodId', params.billingPeriodId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BillingPeriod>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `extendTrialPeriod$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  extendTrialPeriod(
    params: {
      managerId: number;
      billingPeriodId: string;
      body: ExtendTrialPeriodDto
    },
    context?: HttpContext
  ): Observable<BillingPeriod> {
    return this.extendTrialPeriod$Response(params, context).pipe(
      map((r: StrictHttpResponse<BillingPeriod>): BillingPeriod => r.body)
    );
  }

}
